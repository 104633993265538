const errorMessage = {
    name: 'nameIsRequired',
    firstName: 'firstNameRequired',
    lastName: 'lastNameRequired',
    subject: 'subjectIsRequired',
    company_name: 'companyNameRequired',
    domain: 'siteNameRequired',
    company_industry_name: 'industryRequired',
    country: 'countryRequired',
    giftcard_type: 'cardTypeRequired',
    business_type: 'businessTypeRequired',
    role: 'companyRoleRequired',
}

const invalidMessage = {
    firstName: 'validFirstName',
    lastName: 'validLastName',
    name: 'validName',
    subject: 'validSubject',
    company_name: 'validCompanyName'
}


const nospecial = /^[^!-@[-`{-~£]+$/;
const validmail = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/
const specialCharacters = /^(?=.*[~`!@#$%^&*()--+={}[\]|\\:;"'<>,.?/_₹]).*$/

export const emailValidation = email => {
    const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regex.test(email.toLowerCase());
}
export const passwordValidation = password => {
    return password.length > 7
}

export const replaceSpaceWithUnderscore = name => {
    return lowerCase(name.split(' ').join('_'));
}
export const replaceUnderscoreWithSpace = name => {
    return lowerCase(name.split('_').join(' '));
}
export const confirmPassword = (confirmPass, pass) => confirmPass === pass

export const alphabeticStringValidation = val => {
    const regex = /^[A-Za-z\-_]+$/;  //substitute: /^[A-Za-z]+$/without letters
    return regex.test(val);
}
export const zipCodeValidation = val => {
    const regex = /^[0-9]+$/;
    return (regex.test(val) && val.toString().length === 6);
}
export const values = object => object ? Object.values(object) : []

export const keys = object => object ? Object.keys(object) : []

export const isEmpty = value => value !== undefined ? value : ''

export const isEmptyString = value => value === ""

export const lowerCase = values => values.toLowerCase()

export const number = val => {
    const regex = /^[0-9]+$/;
    return regex.test(val);
}

export const getRandomNumber = () => {
    return Math.floor(100000 + Math.random() * 9000000000000);
}

export const mobileNumber = val => {
    const regex = /^[0-9]+$/;
    return (regex.test(val) && val.toString().length === 10);
}

export const fullNameString = val => {
    const regex = /^[a-zA-Z]+ [a-zA-Z]+$/;
    return regex.test(val);
}

export const ValidateCreditCardNumber = val => {
    var visaRegEx = /^(?:4[0-9]{12}(?:[0-9]{3})?)$/;
    var masterCardRegEx = /^(?:5[1-5][0-9]{14})$/;
    var americaExpRegEx = /^(?:3[47][0-9]{13})$/;
    var discoverRegEx = /^(?:6(?:011|5[0-9][0-9])[0-9]{12})$/;
    var isValid = false;
    // test numbers
    // 341140185346626.
    // 375727641414391.
    // 373834279838370.
    // 348719775768795.
    // 372653007683820.

    if (visaRegEx.test(val)) {
        isValid = true;
    } else if (masterCardRegEx.test(val)) {
        isValid = true;
    } else if (americaExpRegEx.test(val)) {
        isValid = true;
    } else if (discoverRegEx.test(val)) {
        isValid = true;
    }
    return isValid
}

export const customValidation = (name, value, data) => {
    switch (name) {
        case "name":
        case "firstName":
        case "lastName":
        case "subject":
        case "company_name":
        case "domain":
        case "country":
        case "company_industry_name":
        case "giftcard_type":
        case "business_type":
        case "role":
            if (!value || value.trim() === "") {
                return errorMessage[name];
            } else if (!nospecial.test(value)) {
                return invalidMessage[name];
            } else {
                return ""
            }
        case "email_id":
            if (!value || value.trim() === "") {
                return '';
            } else if (!value.match(validmail)) {
                return 'validEmail'
            } else {
                return ''
            }
        case "email":
            if (!value || value.trim() === "") {
                return "emailIsRequired";
            } else if (
                !value.match(validmail)
            ) {
                return "validEmail";
            } else {
                return "";
            }
        case "message":
            if (!value || value.trim() === "") {
                return 'enterValidMessage';
            } else {
                return ''
            }
        case 'phone_number':
            if (!value || value.trim() === "") {
                return "phoneIsRequired";
            } else return ''
        case "password":
            if (!value) {
                return "passwordRequired";
            } else if ((value.length < 8) || (value.length > 32)) {
                return "passwordLength";
            } else if (!value.match(/[a-z]/g)) {
                return "atleastLowercase";
            } else if (!value.match(/[A-Z]/g)) {
                return "atleastUppercase";
            } else if (!specialCharacters.test(value)) {
                return 'atLeastSpecialCharaters'
            }
            else if (!value.match(/[0-9]/g)) {
                return "atLeastOneDigit";
            } else {
                return ""
            }
        default: return ''
    }
}
