import React, { useEffect, useState, useRef } from "react";
import { Col, Form, Row, Spinner } from "react-bootstrap";
import { makeStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import CustomInput from "../Common/CustomInput";
import { X, Check2All, CheckCircleFill } from "react-bootstrap-icons";
import CustomInputPassword from "../Common/CustomInputPassword";
import CustomSelectBox from "../Common/CustomSelectBox";
import Checkbox from "@material-ui/core/Checkbox";
import { customValidation } from "../../utils/regex";
import axios from "axios";
import { authDomain, saveSubscription, userSignUp } from "../../Api/SignupApi";
import { toastMessage } from "../../redux/actions";
import { signInSuccess } from "../../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import DoneIcon from '@material-ui/icons/Done';
import {
  getBusinessRole,
  getIndustry,
} from "../../Api/BusinessApi";
import CreditCardIcon from "@material-ui/icons/CreditCard";
import ScrollAnimation from "react-animate-on-scroll";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import CountrySelector from "../Common/SelectorWithSearch";
import giftcardsLogo from "../../assets/images/logo-dark-bg.svg";
import shapeSetup1 from "../../assets/images/mobile-shape-setup-3.svg";
import { purify } from "../../utils/dompurify";
import { isValidPhoneNumber, AsYouType } from "libphonenumber-js";
import CustomCountrySelector from "../Common/CoutrySelector";
import Steps from "./Steps";
import libphonenumbers from 'libphonenumbers';

const initialData = {
  company_name: "",
  email: "",
  password: "",
  phone_number: "",
  domain: "",
  company_industry_name: "",
  country_code: "",
  // giftcard_type: "",
  // business_type: "",
  role: "",
  firstName: "",
  lastName: "",
};

const stepsLine = ["step1line", "step2line", "step3line"]

const useStyles = makeStyles((theme) => ({
  accountStep: {
    width: "100%",
    marginBottom: 20,
    [theme.breakpoints.down('488')]: {
      width: "calc(100% - 20%)",
      margin: 'auto',
    },
    [theme.breakpoints.down('436')]: {
      width: "calc(100% - 16%)",
    },
    "& .MuiStepIcon-root": {
      color: "rgba(255,255,255,0.3)",
      fontSize: "32px",
      "& .MuiStepIcon-text": {
        fill: "rgba(76,141,203,0.6)",
      },
    },
    "& .MuiStepIcon-root.MuiStepIcon-active": {
      color: "#ffee63",
      fontWeight: "bold",
      "& .MuiStepIcon-text": {
        fill: "#241F20",
      },
    },
    "& .MuiStepIcon-root.MuiStepIcon-completed": {
      color: "#ffffff",
    },
    "& .MuiPaper-root": {
      backgroundColor: "transparent",
      maxWidth: "300px",
      width: "100%",
      margin: "auto",
      marginBottom: "80px",
      marginTop: "8px",
      // [theme.breakpoints.down("515")]: {
      //   marginBottom: "60px",
      //   marginTop: "10px",
      // },
      [theme.breakpoints.down("481")]: {
        marginBottom: "60px",
        marginTop: "10px",
        maxWidth: "250px",
      },
    },
    "& .MuiStepConnector-alternativeLabel": {
      top: "15.5px",
    },
    "& .MuiStepConnector-line": {
      borderColor: "#bfe2f7",
      borderTopStyle: "solid",
    },
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: "0",
  },
}));

const RegisterModal = () => {
  const { i18n, t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const dataForm = useRef(null);
  const [activeStep, setActiveStep] = useState(0);
  const [acceptTerms, setAcceptTerms] = useState(false);
  const [formData, setFormData] = useState(initialData);
  const [countryCode, setCountryCode] = useState("+971");
  const [CountryCodeEn, setCountryCodeEn] = useState("AE");
  const [formattedNumber, setformattedNumber] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [industry, setIndustry] = useState([]);
  const [businessRoles, setBusinessRoles] = useState([]);
  // const [giftCardsType, setGiftcardsType] = useState([]);
  // const [businessTypes, setBusinessTypes] = useState([]);
  const [cancelToken, setCancelToken] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [paymentId, setPaymentId] = useState(null);
  const [paymentAttempt, setPaymentAttempt] = useState(false);
  const [paymentModalClose, setPaymentModalClose] = useState(false);
  const [paymentSuccessFlag, setPaymentSuccessFlag] = useState(false);
  const [loader, setLoader] = useState(false);
  const userInfo = useSelector(({ signIn }) => signIn.loggedUser);
  const email = useSelector(({ loader }) => loader.email);
  const isMobileView = useSelector(({ mainpageData }) => mainpageData.isIncludeMobileShape);
  const [error, setError] = useState({});
  const [timeOutData, setTimeOutData] = useState(null);
  const [openSitePreview, setOpenSitePreview] = useState(false);
  const steps = ["1", "2", "3"];
  const lang = localStorage.getItem("i18nextLng")
  const phoneUtil = libphonenumbers.PhoneNumberUtil.getInstance();
  const PNF = libphonenumbers.PhoneNumberFormat;

  useEffect(() => {
    email && setFormData({ ...formData, email });
    // eslint-disable-next-line
  }, [email]);

  const handleChange = (e) => {
    e.persist();
    e.stopPropagation();
    if (timeOutData) {
      clearTimeout(timeOutData);
    }
    const { name, value: updateValue } = e.target;
    let value = purify(updateValue);
    if (name === "domain") value = value?.toLowerCase();

    setFormData({ ...formData, [name]: value });
    setTimeOutData(
      setTimeout(async () => {
        if (name === "domain") {
          const verified = await domainValidation(value);
          setError((error) => ({
            ...error,
            [name]: verified,
          }));
        } else {
          setError({
            ...error,
            [name]: customValidation(name, value),
          });
        }
      }, 200)
    );
  };

  const domainValidation = async (value) => {
    cancelToken?.cancel("Request cancelled");
    switch (true) {
      case !value || value.trim() === "":
        return t("siteNameRequired");
      case !value.match(/^[a-z0-9]/):
        return t("siteNameStartSmallLetters");
      case value.match(/[A-Z]/g):
        return t("siteNameNotAllowedCapitalLetters");
      case value.match(/[ `!@#$%^&*()_+=[\]{};':"\\|,.<>/?~]/):
        return t("siteNameNotAllowedSpecialCharacters");
      case value.length <= 2:
        return t("siteNameIsShort");
      case value.length > 2:
        try {
          setIsLoading(true);
          const source = axios.CancelToken.source();
          const respponse = await authDomain(
            { domainName: value },
            source,
            setCancelToken
          );
          if (respponse) {
            setIsLoading(false);
            return respponse.status === 200 ? "" : t("siteNameNotAvailable");
          } else return "";
        } catch (error) {
          setIsLoading(false);
          if (error.response?.status === 400) {
            return t("siteNameNotAvailable");
          } else if (!axios.isCancel(error)) {
            return "";
          } else return "";
        }
      default: return "";
    }
  };

  useEffect(() => {
    if (openSitePreview) {
      dataForm.current.focus();
    }
  }, [openSitePreview])

  useEffect(() => {
    if (showModal) {
      window.Chargebee.registerAgain();
      const cbInstance = window.Chargebee.getInstance();
      // var planElement = document.querySelector("[data-cb-plan-id='standard']");
      // var product = cbInstance.getProduct(planElement);
      // product.data["cf_sub_test"] = "subscription custom field";
      const cart = cbInstance.getCart();
      const customer = {
        first_name: userInfo["custom:companyName"],
        last_name: " ",
        email: userInfo.email,
        billing_address: {
          first_name: userInfo["custom:companyName"],
          last_name: "",
          company: "",
          phone: userInfo.phone_number || "",
          line1: "",
          line2: "",
        },
      };
      cart.setCustomer(customer);

      cbInstance.setCheckoutCallbacks((cart) => {
        // You can get the plan name for which the checkout happened like below
        // var product = cart.products[0];
        // console.log(`product`, product)
        return {
          loaded: () => {
            setShowModal(false);
          },
          close: () => {
            handleCloseSubscription();
          },
          success: (hostedPageId) => {
            handleSubscriptionSuccess(hostedPageId);
            // Hosted page id will be unique token for the checkout that happened
            // You can pass this hosted page id to your backend
            // and then call our retrieve hosted page api to get subscription details
            // https://apidocs.chargebee.com/docs/api/hosted_pages#retrieve_a_hosted_page
          },
          fail: (value) => {
            handleSubscriptionFailure(value);
          },
        };
      });
    }
    // eslint-disable-next-line
  }, [showModal]);

  useEffect(() => {
    // businessType();
    // giftcardsbyType();
    businessRole();
    getIndustyType();
    // eslint-disable-next-line
  }, []);


  // const businessType = async () => {
  //   try {
  //     const response = await getBusiness();
  //     if (response) {
  //       const { data } = response;
  //       setBusinessTypes(data);
  //     }
  //   } catch (error) {
  //     dispatch(
  //       toastMessage({
  //         status: true,
  //         message: error.message || error?.response?.data?.detail || t("somthingWentWrong"),
  //         type: "error",
  //       })
  //     );
  //   }
  // };

  const businessRole = async () => {
    try {
      const response = await getBusinessRole();
      if (response) {
        const { data } = response;
        setBusinessRoles(data);
      }
    } catch (error) {
      dispatch(
        toastMessage({
          status: true,
          message: error.message || error?.response?.data?.detail || t("somthingWentWrong"),
          type: "error",
        })
      );
    }
  };

  // const giftcardsbyType = async () => {
  //   try {
  //     const response = await getGiftcardsType();
  //     if (response) {
  //       const { data } = response;
  //       setGiftcardsType(data);
  //     }
  //   } catch (error) {
  //     dispatch(
  //       toastMessage({
  //         status: true,
  //         message: error.message || error?.response?.data?.detail || t("somthingWentWrong"),
  //         type: "error",
  //       })
  //     );
  //   }
  // };

  const handleSubscriptionSuccess = async (nonce) => {
    setLoader(true);
    setPaymentSuccessFlag(true);
    setPaymentAttempt(true);
    try {
      const response = await saveSubscription(nonce, formData.domain);
      if (response.data) {
        const { payment_id } = response.data;
        setPaymentId(payment_id);
        setLoader(false);
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setPaymentSuccessFlag(paymentSuccessFlag);
      }
    } catch (error) {
      setLoader(false);
      dispatch(
        toastMessage({
          status: true,
          message: error.message || error?.response?.data?.detail || t("somthingWentWrong"),
          type: "error",
        })
      );
    }
  };

  const handleSiteNamePreview = () => {
    setOpenSitePreview(prev => !prev)
  }

  const handleSubscriptionFailure = () => {
    setPaymentAttempt(true);
    setPaymentSuccessFlag(false);
  };

  const handleOpenModal = (value) => {
    setShowModal(value);
    paymentAttempt && setPaymentAttempt(false);
    paymentModalClose && setPaymentModalClose(false);
  };

  const handleCloseSubscription = () => {
    setPaymentModalClose(true);
  };

  const getIndustyType = async () => {
    try {
      const response = await getIndustry();
      if (response) {
        const { data } = response;
        setIndustry(data);
      }
    } catch (error) {
      dispatch(
        toastMessage({
          status: true,
          message: error.message || error?.response?.data?.detail || t("somthingWentWrong"),
          type: "error"
        })
      );
    }
  };

  const handlePhoneCode = (data) => {
    const { value: changeValue } = data;
    const value = purify(changeValue);
    const isValid = isValidPhoneNumber(value + formData.phone_number);
    setCountryCode(value);
    setError({ ...error, phone_number: !isValid ? t("enterValidNumber") : "" });

    const formatter = new AsYouType();
    formatter.input(value);
    setCountryCodeEn(formatter.country);
  };

  const handleCountry = (data) => {
    setFormData({ ...formData, country_code: data })
    setError({ ...error, country_code: "" });
  }

  const handlePhoneNumber = (e) => {
    const { value: changeValue } = e.target;
    const value = purify(changeValue);
    setFormData({ ...formData, phone_number: value || null });

    const isValid = isValidPhoneNumber(countryCode + value);
    const number = phoneUtil.parseAndKeepRawInput(value, CountryCodeEn);
    setformattedNumber(phoneUtil.format(number, PNF.INTERNATIONAL));
    
    setError({
      ...error,
      phone_number: value?.length
        ? !isValid || phoneUtil.getNumberType(number) !== 1
          ? t("enterValidNumber")
          : ""
        : "",
    });


  };

  const handlePhoneNumberValidate = () => {
    if (error["phone_number"]) return error["phone_number"]
  }

  const isError = async (step) => {
    const {
      email,
      firstName,
      lastName,
      company_name,
      domain,
      company_industry_name,
      country_code,
      // giftcard_type,
      // business_type,
      role,
      password,
      phone_number
    } = formData;
    let validationErrors = {};
    switch (step) {
      case 0:
        Object.keys({ email }).forEach((name) => {
          const error = customValidation(name, formData[name]);
          if (error && error.length > 0) {
            validationErrors[name] = error;
          }
        });
        if (Object.keys(validationErrors).length > 0) {
          setError(validationErrors);
          return true;
        } else return false;
      case 1:
        Object.keys({
          firstName,
          lastName,
          role,
          phone_number
        }).forEach((name) => {
          const error = (name === "phone_number") ? handlePhoneNumberValidate(formData[name]) : customValidation(name, formData[name]);
          if (error && error.length > 0) {
            validationErrors[name] = error;
          }
        });
        if (Object.keys(validationErrors).length > 0) {
          setError(validationErrors);
          return true;
        } else return false;
      case 2:
        const domainError = await domainValidation(domain)
        Object.keys({
          company_industry_name,
          country_code,
          company_name,
          // giftcard_type,
          // business_type,
          domain,
          password
        }).forEach((name) => {
          const error = (name === "domain") ? domainError : customValidation(name, formData[name]);
          if (error && error.length > 0) {
            validationErrors[name] = error;
          }
        });
        if (Object.keys(validationErrors).length > 0) {
          setError(validationErrors);
          setOpenSitePreview(true)
          return true;
        } else return false;
      default: return false;
    }
  };


  const handleNext = async (e, step) => {
    e.preventDefault();
    if (!(await isError(step))) {
      if (step === 2) {
        submitUserData();
      } else {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
    }
  };

  const submitUserData = async (e) => {
    setLoader(true);
    const { firstName, lastName, phone_number, ...otherData } = formData;
    try {
      const response = await userSignUp({
        first_name: firstName,
        last_name: lastName,
        phone_number: phone_number ? formattedNumber : null,
        is_foodics_user: true,
        ...otherData,
      }, i18n.language );
      if (response) {
        dispatch(
          signInSuccess({
            first_name: firstName,
            last_name: lastName,
            ...otherData,
          })
        );
        if (response.status === 201) {
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
      }
      setLoader(false);
    } catch (error) {
      setLoader(false);
      if (error?.response?.data?.detail) {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
      }
      Array.isArray(error?.response?.data?.detail) &&
        // eslint-disable-next-line
        error?.response?.data?.detail.map((value) => {
          if (value.loc[1] && value.type) {
            let cloneContactError = { ...error };
            cloneContactError[value.loc[1]] = value?.msg;
            setError(cloneContactError);
          }
        });
      dispatch(
        toastMessage({
          status: true,
          message:
            t("somthingWentWrong"),
          type: "error",
        })
      );
    }
  };

  const handleRemove = (name) => {
    setError({
      ...error,
      [name]: "",
    });
    setFormData({ ...formData, [name]: "" });
  };

  function getStepContent(stepIndex) {
    switch (stepIndex) {
      case 0:
        return (
          <div className="stepAccount">
            <div className="setup-account-title">
              <h4>{t("email")}</h4>
            </div>
            <Row>
              <Col md={12}>
                <Form onSubmit={(e) => handleNext(e, activeStep)}>
                  <div className="step-account-box">
                    <div className="set-email">
                      <CustomInput
                        type="text"
                        name="email"
                        onChange={handleChange}
                        value={formData.email}
                        label={t("enterYourworkEmail")}
                      />
                      {formData.email ? (
                        <span
                          onClick={() => handleRemove("email")}
                          className="custom-close"
                        >
                          {t("clear")}
                        </span>
                      ) : null}
                      <p className="input-error-msg">
                        {error["email"] && t(`${error["email"]}`)}
                      </p>
                    </div>
                  </div>
                  <div className="actionBtn text-center">
                    <Button type="submit">
                      {t("continue")}
                      {/* <ArrowRightShort /> */}
                    </Button>
                    <small className="have-account">
                      {t("alreadyAccount")}
                      <span
                        className="ml-1"
                        onClick={() => history.push("/login")}
                      >
                        {t("Login")}
                      </span>{" "}
                    </small>
                  </div>
                </Form>
              </Col>
            </Row>
          </div>
        );
      case 1:
        return (
          <div className="stepAccount">
            <div className="setup-account-title">
              <h4>{t("profileDetails")}</h4>
            </div>
            <Row>
              <Col md={12}>
                <div className="step-account-box">
                  <form onSubmit={(e) => handleNext(e, activeStep)}>
                    <div className="set-email">
                      <CustomInput
                        type="text"
                        name="firstName"
                        value={formData.firstName}
                        onChange={handleChange}
                        label={t("firstName")}
                        autoFocus
                      />
                      {formData.firstName ? (
                        <span
                          onClick={() => handleRemove("firstName")}
                          className="custom-close"
                        >
                          {t("clear")}
                        </span>
                      ) : null}
                      <p className="input-error-msg">
                        {error["firstName"] && t(`${error["firstName"]}`)}
                      </p>
                    </div>
                    <div className="set-email">
                      <CustomInput
                        type="text"
                        name="lastName"
                        value={formData.lastName}
                        onChange={handleChange}
                        label={t("lastName")}
                      />
                      {formData.lastName ? (
                        <span
                          onClick={() => handleRemove("lastName")}
                          className="custom-close"
                        >
                          {t("clear")}
                        </span>
                      ) : null}
                      <p className="input-error-msg">
                        {error["lastName"] && t(`${error["lastName"]}`)}
                      </p>
                    </div>
                    <div className="set-email">
                      <CustomSelectBox
                        label={t("myRoleInCompany")}
                        data={businessRoles.map((data) => {
                          return {
                            ...data,
                            name: data.role_name,
                            value: data.role_name,
                          };
                        })}
                        name="role"
                        value={formData.role}
                        {...{
                          error,
                          setError,
                          formData,
                          setFormData,
                          handleChange,
                        }}
                      />
                    </div>
                    <div className="contact-numbers">
                      <div className="position-relative d-flex justify-content-between">
                        <CountrySelector
                          handleCode={handlePhoneCode}
                          label={t("coutryCode")}
                        />
                        <CustomInput
                          type="number"
                          name="phone_number"
                          value={formData.phone_number}
                          onChange={handlePhoneNumber}
                          label={t("phoneNumber")}
                        />
                        {formData.phone_number ? (
                          <span
                            onClick={() => handleRemove("phone_number")}
                            className="custom-close"
                          >
                            {t("clear")}
                          </span>
                        ) : null}
                      </div>
                      <p className="text-danger m-0">
                        {error["phone_number"] && t(`${error["phone_number"]}`)}
                      </p>
                    </div>
                    {/* <div className="set-email site-name-preview ">
                      <CustomInput
                        type="text"
                        name="domain"
                        value={formData.domain}
                        onChange={handleChange}
                        label={t("siteName")}
                      />
                      {formData.domain ? (
                        <span
                          onClick={() => handleRemove("domain")}
                          className="custom-close"
                        >
                          {t("clear")}
                        </span>
                      ) : null}
                      {formData.domain.trim() ? (
                        <div className="d-flex">
                          <p className="site-name-msg mb-0">{t("isThat")}:</p>
                          <p className="site-name-msg mb-0">
                            https://{formData.domain}.giftcardsby.com
                          </p>
                        </div>
                      ) : null}
                      {error["domain"] ? (
                        <p className="input-error-msg mb-0">
                          {error["domain"] && t(`${error["domain"]}`)}
                        </p>
                      ) : null}
                    </div> */}
                    <div className="actionBtn text-center">
                      <Button disabled={isLoading} type="submit">
                        {t("continue")}
                        {/* <ArrowRightShort /> */}
                      </Button>
                    </div>
                  </form>
                </div>
              </Col>
            </Row>
          </div>
        );
      case 2:
        return (
          <div className="stepAccount">
            <div className="setup-account-title">
              <h4>{t("businessDetails")}</h4>
            </div>
            <Row>
              <Col md={12}>
                <div className="step-account-box">
                  <Form onSubmit={(e) => handleNext(e, activeStep)}>
                    <Row>
                      <Col md={12}>
                        <div className="set-email">
                          <CustomInput
                            type="text"
                            name="company_name"
                            value={formData.company_name}
                            onChange={handleChange}
                            label={t("companyName")}
                          />
                          {formData.company_name ? (
                            <span
                              onClick={() => handleRemove("company_name")}
                              className="custom-close"
                            >
                              {t("clear")}
                            </span>
                          ) : null}
                          <p className="input-error-msg">
                            {error["company_name"] &&
                              t(`${error["company_name"]}`)}
                          </p>
                        </div>
                      </Col>
                      <Col md={12}>
                        <div className="set-email">
                          <CustomSelectBox
                            label={t("companyNameIs")}
                            data={industry.map((data) => {
                              return {
                                ...data,
                                name: data.industry_name,
                                value: data.industry_name,
                              };
                            })}
                            name="company_industry_name"
                            value={formData.company_industry_name}
                            {...{
                              error,
                              setError,
                              formData,
                              setFormData,
                              handleChange,
                            }}
                          />
                        </div>
                      </Col>
                      <Col md={12}>
                        <div className="contact-numbers">
                          <CustomCountrySelector
                            handleCode={handleCountry}
                            label={t("andIsRegister")}
                            data={formData.country_code}
                            name="country_code"
                            {...{ formData, setFormData, error, setError }}
                          />
                          {/* <CustomSelectBox
                            label={t("andIsRegister")}
                            data={country.map((data) => {
                              return {
                                ...data,
                                name: data.name,
                                value: data.name,
                              };
                            })}
                            name="country"
                            value={formData.country}
                            {...{
                              error,
                              setError,
                              formData,
                              setFormData,
                              handleChange,
                            }}
                          /> */}
                        </div>
                      </Col>
                      {/* <Col md={12}>
                        <div className="set-email">
                          <CustomSelectBox
                            label={t("weWant")}
                            data={businessTypes.map((data) => {
                              return {
                                ...data,
                                name: data.business_type,
                                value: data.business_type,
                              };
                            })}
                            name="business_type"
                            value={formData.business_type}
                            {...{
                              error,
                              setError,
                              formData,
                              setFormData,
                              handleChange,
                            }}
                          />
                        </div>
                      </Col>
                      <Col md={12}>
                        <div className="set-email">
                          <CustomSelectBox
                            label={t("giftcardsFor")}
                            data={giftCardsType.map((data) => {
                              return {
                                ...data,
                                name: data.marketing_type,
                                value: data.marketing_type,
                              };
                            })}
                            name="giftcard_type"
                            value={formData.giftcard_type}
                            {...{
                              error,
                              setError,
                              formData,
                              setFormData,
                              handleChange,
                            }}
                          />
                        </div>
                      </Col> */}
                      <Col md={12}>
                        {/* <div className="set-email site-name-preview ">
                          <CustomInput
                            type="text"
                            name="domain"
                            value={formData.domain}
                            onChange={handleChange}
                            label={t("siteName")}
                          />
                          {formData.domain ? (
                            <span
                              onClick={() => handleRemove("domain")}
                              className="custom-close"
                            >
                              {t("clear")}
                            </span>
                          ) : null}
                          {formData.domain.trim() ? (
                            <div className="d-flex">
                              <p className="site-name-msg mb-0">
                                {t("isThat")}:
                              </p>
                              <p className="site-name-msg mb-0">
                                https://{formData.domain}.giftcardsby.com
                              </p>
                            </div>
                          ) : null}
                          {error["domain"] ? (
                            <p className="input-error-msg mb-0">
                              {error["domain"] && t(`${error["domain"]}`)}
                            </p>
                          ) : null}
                        </div> */}
                        <div className="set-email site-name-preview">
                          <button type="button" onClick={() => handleSiteNamePreview()} >{t("yourURLname")}  <i class="far fa-edit"></i></button>
                          {(openSitePreview) ? <div className="name-preview">
                            <div className="site_name_preview" > <input ref={dataForm} name="domain" value={formData.domain} onChange={handleChange} type="text" placeholder={t("username")} /> / giftcardsby.com </div>
                            {error["domain"] ? <X className="text-danger font_size" /> : <DoneIcon className="text-blue" />}
                          </div> : null}
                          <p className="input-error-msg">
                            {error["domain"] &&
                              t(`${error["domain"]}`)}
                          </p>
                        </div>
                      </Col>
                      <Col md={12} >
                        <div className="w-100 text-right mb-2">
                        </div>
                        <div className="position-relative input_password">

                          <CustomInputPassword
                            name="password"
                            value={formData.password}
                            handleChange={handleChange}
                            error={error["password"]}
                            label={t("password")}
                          />
                          {formData.password ? <span onClick={() => handleRemove('password')} className="custom-close">{t('clear')}</span> : null}
                        </div>
                      </Col>
                      <Col md={12}>
                        <div className="terms-contract-sec">
                          <div className="check-tp">
                            <Checkbox
                              color="primary"
                              checked={acceptTerms}
                              inputProps={{
                                "aria-label": "uncontrolled-checkbox",
                              }}
                              onChange={(e) => setAcceptTerms(e.target.checked)}
                            />
                          </div>
                          <p className="m-0 mb-3">
                            {t("agreeTo")}
                            <a rel="noopener noreferrer" href={lang === "ar" ? "/terms-and-conditions/ar" : "/terms-and-conditions"} target="_blank">
                              {" "}
                              {t("termsOfServese")}{" "}
                            </a>
                            {t("and")}
                            <a rel="noopener noreferrer" href={lang === "ar" ? "/privacy-policy/ar" : "/privacy-policy"} target="_blank">
                              {" "}
                              {t("privacyPolicy")}{" "}
                            </a>
                          </p>
                        </div>
                        {/* <p className="agree">
                                                        {t("fullContract")}
                                                        <a href="/privacy-policy" target="_blank"> {t('contract')} </a>
                                                    </p> */}
                      </Col>
                    </Row>
                    <div className="actionBtn text-center">
                      <Button type="submit" disabled={loader || !acceptTerms}>
                        {loader && (
                          <Spinner
                            className="spinner-loaderIcon"
                            animation="border"
                            role="status"
                          >
                            <span className="visually-hidden"></span>
                          </Spinner>
                        )}

                        {loader ? t("pleaseWait") : t("signUp")}
                        {/* {!loader && <ArrowRightShort />} */}
                      </Button>
                    </div>
                  </Form>
                </div>
              </Col>
            </Row>
          </div>
        );
      case 3:
        return (
          <div className="stepAccount">
            <div className="setup-account-title">
              <h4>{t("freeTrial")}</h4>
            </div>
            <Row>
              <Col md={12}>
                <div className="subscribe-info">
                  <p> {t("chargeNow")}</p>
                  <p className="mb-0"> {t("doThisLatter")} </p>
                  <div>
                    <button
                      onClick={() => handleOpenModal(true)}
                      className="btn btn-default subscribe-btn"
                      data-cb-type="checkout"
                      data-cb-plan-id="standard"
                      data-cb-item-0="standard-plan-USD-Monthly"
                    // data-cb-item-0-quantity="1"
                    >
                      <CreditCardIcon /> {t("SUBSCRIBE")}
                    </button>
                    {/* <CustomButton handalClick={handleSubscription} className='subscribe-btn'> </CustomButton> */}
                  </div>
                </div>
              </Col>
            </Row>
            <div className="actionBtn text-center">
              <Button
                type="button"
                disabled={loader}
                onClick={(e) => handleNext(e, activeStep)}
              >
                {loader && (
                  <Spinner
                    className="spinner-loaderIcon"
                    animation="border"
                    role="status"
                  >
                    <span className="visually-hidden"></span>
                  </Spinner>
                )}
                {!loader && <Check2All className="check-aerrow-all" />}
                {loader ? t("pleaseWait") : t("continue")}
              </Button>
            </div>
          </div>
        );
      default:
        return null;
    }
  }

  return (
    <>
      {false ? (
        <div className="loader-wrap">
          <div className="loader"></div>
        </div>
      ) : null}
      <div className="signup-modal">
        <div
          className={`${activeStep === 0 || activeStep === 2
            ? "signup_setup shape_one"
            : `signup_setup shape_two ${activeStep === 1 ? "middle_step" : ""}`
            }`}
        >
          {isMobileView ? <img src={shapeSetup1} alt="logo" /> : null}
        </div>

        <div className="modal-header">
          <div className="modal-logo" onClick={() => lang === "en" ? history.push("/") : history.push("/ar")}>
            <img src={giftcardsLogo} alt="logo" />
          </div>
          <button
            type="button"
            className="close"
            onClick={() => lang === "en" ? history.push("/") : history.push("/ar")}
          >
            {" "}
            <X />{" "}
          </button>
        </div>
        <Row className="justify-content-center bg-blue m-0">
          <Col lg={8}>
            <div className="setup-account-heading text-center">
              <h3>{t("setupNewAccount")}</h3>
              <p>{t(stepsLine[activeStep])}</p>
              {/* <p>{t("let'sNewAccount")}</p> */}
            </div>
          </Col>
        </Row>
        <Row className="justify-content-center m-0">
          <div className="signUp-box">
            <div className={classes.accountStep}>
              <Stepper activeStep={activeStep} alternativeLabel>
                {steps.map((label) => (
                  <Step key={label}>
                    <StepLabel StepIconComponent={Steps}>
                      {""}
                    </StepLabel>
                  </Step>
                ))}
              </Stepper>
              <div className="account-details">
                <div className="new-account-box">
                  {activeStep === steps.length ? (
                    <div className="text-center">
                      <div className="account-create">
                        <CheckCircleFill />
                        <h6>{t("registerSuccessful")}</h6>
                        {paymentId && (
                          <p>
                            {t("PaymentID")}: {paymentId}
                          </p>
                        )}
                        <p>{`${t("verificationLink")} ${formData.email}. ${t(
                          "verifyAndConfirm"
                        )}`}</p>
                      </div>
                      <div className="actionBtn text-center mt-3">
                        <Button
                          className="btn btn-primary"
                          onClick={() => lang === "en" ? history.push("/") : history.push("/ar")}
                        >
                          {t("DONE")}
                        </Button>
                      </div>
                    </div>
                  ) : (
                    <div>
                      <Typography className={classes.instructions}>
                        {getStepContent(activeStep)}
                      </Typography>
                      <ScrollAnimation
                        animateIn={
                          activeStep === 0
                            ? "animate__fadeInUp"
                            : "animate__fadeInDown"
                        }
                        animateOnce={true}
                        duration={0.4}
                        delay={600}
                      ></ScrollAnimation>
                    </div>
                  )}
                </div>
                {activeStep === 0 && (
                  <>
                    <div className="left-text">
                      <p> {t("createYour")} </p>
                      <p> {t("ownGiftCard")} </p>
                    </div>
                    <div className="right-text">
                      <p> {t("easySetup")} </p>
                      <p> {t("powerfullReturns")} </p>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </Row>
      </div>
    </>
  );
};

export default RegisterModal;
